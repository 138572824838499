import React, { useState } from 'react';
import {
  Input,
  Row,
  Col,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { DropzoneComponent } from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server';
import { isEmpty, find, get } from 'lodash-es';
import { displayError, displaySuccess } from '../../../../../../Utils';
import { baseURL } from '../../../../../../api';
import confirm from '../../../../components/common/confirm';
import CustomButton from '../../../../components/common/button';
import CheckBox from '../../../../components/common/checkBox';
import CSSBoxModel from '../../../../components/common/breakpoint/boxModel';
import { createStore } from '../../../../../../rootStore';
import LinkToolBox from '../LinkToolBox';
import './index.scss';
import { renderBreakpoint } from '../../../../components/common/textStyles';
import { MediaSelectBox } from '../../../../components/fields/site';
import {
  spinCountDec,
  spinCountInc,
  useCreateMediaMutation,
  useGetMediaFilesQuery,
  useUpdateMediaMutation,
} from '../../../../../../common/api/apiSlice';
import { useSite } from 'common/hooks';

function MediaToolBox(props) {
  const {
    media,
    toggle,
    instanceId,
    isEmailPage,
    isEditAction,
    selectedString,
    selectedTextBlockId,
    updateTextBlockData,
    onMediaBoxSaveButton,
  } = props;

  const store = createStore();

  const site = useSite();
  const siteId = get(site, 'id', null);

  let tempShowDevice = false;
  if (media?.tablet_image_id && media?.tablet_image_id !== media?.file_id) {
    tempShowDevice = true;
  }
  if (media?.phone_image_id && media?.phone_image_id !== media?.file_id) {
    tempShowDevice = true;
  }

  const [modal, setModal] = useState(false);
  const [activeDevice, setActiveDevice] = useState(tempShowDevice ? 'desktop' : 'all');
  const [showDevice, setShowDevice] = useState(tempShowDevice);
  const [hovering, setHovering] = useState(false);
  const [rewriteValue, setRewriteValue] = useState(false);
  const [params, setParams] = useState(null);
  const { refetch, data: mediaData } = useGetMediaFilesQuery(instanceId);
  const [createMedia] = useCreateMediaMutation();
  const [updateMedia] = useUpdateMediaMutation();

  let myDropzone;

  useState(() => {
    if (media) {
      setParams(media);
      myDropzone = null;
    }
  }, [props]);

  const onHandleClose = () => {
    toggle();
  };

  const onFileSelect = (e) => {
    const { value } = e;
    if (activeDevice === 'tablet') {
      setParams({ ...params, tablet_image_id: value });
    } else if (activeDevice === 'phone') {
      setParams({ ...params, phone_image_id: value });
    } else {
      setParams({ ...params, file_id: value });
    }
  };

  const onHoverImageSelect = (e) => {
    setParams({ ...params, hover_image_id: e ? e.value : null });
  };

  const onMediaCaptionChange = (e) => {
    setParams({ ...params, caption: e.target.value });
  };

  const onMediaAltTextChange = (e) => {
    setParams({ ...params, alt_text: e.target.value });
  };

  const onClickableThumbnail = (e) => {
    const { checked } = e.target;
    setParams({ ...params, clickable_thumbnail: checked });
  };

  const onHandleInsertMedia = async () => {
    try {
      const sendParams = { ...params };

      if (!params.file_id) {
        displayError('Please select file.');
      } else {
        sendParams.text_block_id = selectedTextBlockId;

        let response = null;
        if (sendParams.id) {
          response = await updateMedia({ ...sendParams, instance_id: instanceId });
        } else {
          response = await createMedia({ ...sendParams, instance_id: instanceId });
        }
        if (response && response?.data?.meta?.is_success) {
          const { data, meta } = response.data;
          displaySuccess(meta?.messages);
          onMediaBoxSaveButton({ ...data });
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const handleSaveBreakpoint = (data) => {
    if (data) {
      setParams({ ...params, break_point: data });
    }
  };

  const onLinkBoxSaveButton = (linkData) => {
    if (!isEmpty(linkData)) {
      updateTextBlockData({ ...params, link_id: linkData?.id }, linkData);
      setParams({ ...params, link_id: linkData?.id });
      setModal(!modal);
    }
  };

  const handleUnlink = () => {
    updateTextBlockData({ ...params, link_id: null }, { site_phone: true });
    setParams({ ...params, link_id: null });
    setModal(!modal);
  };

  const onHandleDeviceButton = () => {
    if (showDevice) {
      setActiveDevice('all');
      setShowDevice(!showDevice);
      setParams({ ...params, tablet_image_id: params?.file_id, phone_image_id: params?.file_id });
    } else {
      setActiveDevice('desktop');
      setShowDevice(!showDevice);
    }
  };

  const onSuccess = async (dropzoneData) => {
    const response = JSON.parse(dropzoneData.xhr.response);
    if (response.is_success) {
      refetch(instanceId).then((res) => {
        if (res.isSuccess) {
          setParams({
            ...params,
            file_id: activeDevice === 'all' || activeDevice === 'desktop' ? response.data.id : params?.file_id,
            tablet_image_id: activeDevice === 'tablet' ? response.data.id : params?.file_id,
            phone_image_id: activeDevice === 'phone' ? response.data.id : params?.file_id,
          });
        }
      });
    }
  };

  const onError = async (data) => {
    if (!isEmpty(data) && !rewriteValue) {
      const mainDiv = document.getElementsByClassName('filepicker dropzone')[0];
      if (data.xhr === undefined) {
        const { previewTemplate: { textContent } } = data;
        displayError(textContent);
        setTimeout(() => {
          mainDiv.removeChild(data.previewElement);
        }, 3000);
      } else {
        const { xhr: { response } } = data;
        const fileName = data.upload.filename;
        if (JSON.parse(response)?.messages === 'File already exists') {
          const dialog = await confirm({
            title: (<strong>Confirm!</strong>),
            message: `File: ${fileName} already exists, do you want to overwrite the file?`,
            confirmText: 'Yes',
            confirmColor: 'success',
            cancelColor: 'btn btn-danger',
            cancelText: 'No',
            object: true,
          });
          const result = await dialog.open();
          if (result) {
            setRewriteValue(true);
            handleRewriteMedia(data);
          } else {
            mainDiv.removeChild(data.previewElement);
          }
        } else if (JSON.parse(response)?.messages === 'Invalid file format') {
          displayError('Can not upload this file, invalid file format.');
          setTimeout(() => {
            mainDiv.removeChild(data.previewElement);
          }, 3000);
        }
      }
    }
  };

  const handleRewriteMedia = (fileData) => {
    myDropzone.removeFile(fileData);
    myDropzone.addFile(fileData);
    setTimeout(() => {
      setRewriteValue(false);
    }, [2000]);
  };

  let selectedFileId;
  if (activeDevice === 'all' || activeDevice === 'desktop') {
    selectedFileId = params?.file_id;
  } else if (activeDevice === 'tablet') {
    selectedFileId = params?.tablet_image_id;
  } else if (activeDevice === 'phone') {
    selectedFileId = params?.phone_image_id;
  }

  let selectedFileUrl;
  if (hovering) {
    selectedFileUrl = find(mediaData?.media, (m) => m.id === params?.hover_image_id)?.media_url;
  } else if (activeDevice === 'all' || activeDevice === 'desktop') {
    selectedFileUrl = find(mediaData?.media, (m) => m.id === params?.file_id)?.media_url;
  } else if (activeDevice === 'tablet') {
    selectedFileUrl = find(mediaData?.media, (m) => m.id === params?.tablet_image_id)?.media_url;
  } else if (activeDevice === 'phone') {
    selectedFileUrl = find(mediaData?.media, (m) => m.id === params?.phone_image_id)?.media_url;
  }

  const dropzoneComponentConfig = {
    postUrl: `${baseURL}/instances/${instanceId}/files/?rewrite=${rewriteValue}&context=media`,
  };

  const eventHandlers = {
    init: (dropzone) => {
      myDropzone = dropzone;
    },
    drop: () => store.dispatch(spinCountInc()),
    complete: () => store.dispatch(spinCountDec()),
    dragenter: () => { },
    dragover: () => { },
    success: onSuccess,
    error: onError,
  };

  const djsConfig = {
    dictFileTooBig: 'File is too big, max filesize: 1GB.',
    dictDefaultMessage: '',
    maxFilesize: 1000,
    thumbnailHeight: 65,
    maxThumbnailFilesize: 11111,
    timeout: 300000,
    clickable: false,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="preview-container">
              <img data-dz-thumbnail className="img-thumbnail border-0" alt="" />
              <i className="fal fa-file preview-icon" />
            </div>
          </div>
          <div className="ps-3 pt-2 pe-2 pb-1 w-70 dz-details position-relative">
            <div>
              {' '}
              <span data-dz-name />
              {' '}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div
              className="progress progress-striped active"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="0"
            >
              <div className="progress-bar progress-bar-success dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message"><span data-dz-errormessage /></div>
          </div>
        </div>
      </div>,
    ),
  };

  const breakpointObj = {
    isEmailPage,
    siteId,
    id: params?.id,
    showSettingType: true,
    objectType: 'Media',
    onChange: handleSaveBreakpoint,
    breakPoint: params?.break_point,
  };

  return (
    <>
      <Modal
        container={window.modalRef}
        isOpen
        className="MediaToolBox"
      >
        <ModalHeader toggle={onHandleClose}><b>Media Tool</b></ModalHeader>
        <ModalBody>
          <DropzoneComponent
            config={dropzoneComponentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          >
            <Row>
              <Col className="col-6">
                <Row>
                  <Col className="col-3 pb-2">
                    <Label><b>File:</b></Label>
                  </Col>
                  <Col className="col-9 pb-2">
                    <Row className="BreakPoint align-items-center">
                      <ul>
                        <li className="w-100">
                          <MediaSelectBox
                            value={selectedFileId}
                            onchange={(e) => onFileSelect(e)}
                          />
                        </li>
                        {!isEmailPage && (
                          <li className="bp-responsivebtn d-flex" style={{ alignItems: 'center' }}>
                            {showDevice && (
                              <>
                                <Button
                                  className={
                                    `breakpoint-txt-btn ${activeDevice === 'desktop' ? 'custom-text-color-blue' : ''}`
                                  }
                                  onClick={() => setActiveDevice('desktop')}
                                >
                                  <i className="fal fa-fw fa-desktop fa-2x" aria-hidden="true" />
                                </Button>
                                <Button
                                  className={
                                    `breakpoint-txt-btn ${activeDevice === 'tablet' ? 'custom-text-color-blue' : ''}`
                                  }
                                  onClick={() => setActiveDevice('tablet')}
                                >
                                  <i className="fal fa-fw fa-tablet-alt fa-2x" aria-hidden="true" />
                                </Button>
                                <Button
                                  className={
                                    `breakpoint-txt-btn ${activeDevice === 'phone' ? 'custom-text-color-blue' : ''}`
                                  }
                                  onClick={() => setActiveDevice('phone')}
                                >
                                  <i className="fal fa-fw fa-mobile-alt fa-2x" aria-hidden="true" />
                                </Button>
                                <span style={{ margin: '0 2.5px', fontSize: '12px' }}>┊</span>
                              </>
                            )}
                            <Button
                              className={`breakpoint-txt-btn ${isEmpty(activeDevice) || activeDevice === 'all' ? 'custom-text-color-blue' : ''}
                            `}
                              onClick={onHandleDeviceButton}
                            >
                              <i className="fal fa-fw fa-link fa-2x" aria-hidden="true" />
                            </Button>
                          </li>
                        )}
                      </ul>
                    </Row>
                  </Col>
                </Row>
                {!isEmailPage
                && (
                  <Row>
                    <Col className="col-3 pb-2">
                      <Label><b>Hover Image:</b></Label>
                    </Col>
                    <Col className="col-9 pb-2">
                      <MediaSelectBox
                        value={params?.hover_image_id}
                        onchange={(e) => onHoverImageSelect(e)}
                        isClearable
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="col-3 pb-2">
                    <Label><b>Display:</b></Label>
                  </Col>
                  <Col className="col-9 pb-2">
                    {renderBreakpoint({ ...breakpointObj, settingName: 'display', showSettingType: false })}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3 pb-2">
                    <Label><b>Alignment:</b></Label>
                  </Col>
                  <Col className="col-9 pb-2">
                    {renderBreakpoint({ ...breakpointObj, settingName: 'image-align', showSettingType: false })}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3 pb-2">
                    <b>Width:</b>
                  </Col>
                  <Col className="col-9 pb-2">
                    {renderBreakpoint({ ...breakpointObj, settingName: 'width' })}
                  </Col>
                </Row>
                {!isEmailPage
                && (
                  <Row>
                    <Col className="col-3 pb-2">
                      <b>Maximum Width:</b>
                    </Col>
                    <Col className="col-9 pb-2">
                      {renderBreakpoint({ ...breakpointObj, settingName: 'max-width' })}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="col-3 pb-2">
                    <b>Alt Text</b>
                  </Col>
                  <Col className="col-9 pb-2">
                    <Input type="text" onChange={onMediaAltTextChange} value={params?.alt_text || ''} />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3 pb-2">
                    <b>Caption</b>
                  </Col>
                  <Col className="col-9 pb-2">
                    <Input type="text" onChange={onMediaCaptionChange} value={params?.caption || ''} />
                  </Col>
                </Row>
                {!isEmailPage
                && (
                  <Row>
                    <Col className="col-12">
                      <b>
                        <CheckBox
                          title="Can be a clickable thumbnail?"
                          checked={params?.clickable_thumbnail}
                          onChange={(e) => onClickableThumbnail(e)}
                        />
                      </b>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col className="col-6">
                <Row>
                  <Col className="media-preview-box">
                    <div className="preview-original-insidemedia">
                      <img
                        className="media-preview-content"
                        src={selectedFileUrl}
                        alt=""
                        onMouseOver={() => setHovering(true)}
                        onFocus={() => undefined}
                        onMouseOut={() => setHovering(false)}
                        onBlur={() => undefined}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>Original Dimensions: </b>
                    {params?.file_dimensions}
                  </Col>
                </Row>
              </Col>
            </Row>
            <CSSBoxModel
              labelName={<b>Padding (inside):</b>}
              eleTop={renderBreakpoint({ ...breakpointObj, settingName: 'padding-top' })}
              eleLeft={renderBreakpoint({ ...breakpointObj, settingName: 'padding-left' })}
              eleRight={renderBreakpoint({ ...breakpointObj, settingName: 'padding-right' })}
              eleBottom={renderBreakpoint({ ...breakpointObj, settingName: 'padding-bottom' })}
            />
            <Row>
              <Col>
                <CustomButton
                  size="md"
                  color="info"
                  title="Link Image"
                  disabled={isEmpty(params?.file_id)}
                  onClick={() => setModal(!modal)}
                  className="mt-2 rounded fw-bold"
                />
              </Col>
            </Row>
          </DropzoneComponent>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={onHandleClose}>
            <b>Cancel</b>
          </Button>
          <Button
            color="success"
            onClick={() => onHandleInsertMedia(isEditAction)}
          >
            <b>{isEditAction ? 'Update' : 'Save'}</b>
          </Button>
        </ModalFooter>
      </Modal>
      {modal && (
        <LinkToolBox
          linkId={params?.link_id}
          toggle={() => setModal(!modal)}
          showUnlinkButton
          isMedia
          instanceId={instanceId}
          isEmailPage={isEmailPage}
          onUnlink={handleUnlink}
          selectedString={selectedString}
          selectedTextBlockId={selectedTextBlockId}
          onLinkBoxSaveButton={onLinkBoxSaveButton}
        />
      )}
    </>
  );
}
export default MediaToolBox;
