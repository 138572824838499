import Base from './Base';

interface BriefingInfo {
  include_about_page:        boolean;
  include_contact_page:      boolean;
  contact_form_above_footer: boolean;
  product_pages:             any[];
};

class Briefing extends Base {
  id?:                   string;
  site_id?:              string | number;
  site_created_at?:      null;
  info?:                 BriefingInfo;
  project_manager_name?: null;
  adviser_name?:         null;
  pm_only?:              boolean;
  adviser_completed?:    boolean;
  bdq_request_sent?:     boolean;
  bdq_completed?:        boolean;
  content_created?:      boolean;

  constructor(props) {
    super(props);
    Object.assign(this, props);
  }
};

export default Briefing;
