import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { isArray } from 'lodash-es';
import { Action, Middleware, combineReducers } from 'redux';
import { apiSlice, pageDataSlice, searchTextSlice, sidebarSlice, spinSlice, windowSizeSlice, deviceSlice } from '../common/api/apiSlice';
import { displayError } from '../Utils';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

const rtkQueryErrorLogger = () => (next: (arg0: any) => any) => (
  action: {
    payload: {
      data: {
        messages: any;
        data: any[];
      };
    };
    meta: {
      arg: {
        originalArgs: any;
      };
    };
  },
) => {
  if (isRejectedWithValue(action)) {
    if (action.meta.arg.originalArgs.ignoreErrors) {
      return next(action);
    }

    let message = action.payload.data.messages;

    if (isArray(action.payload.data.data)) {
      action.payload.data.data.forEach((m: any) => message += `\n${m}`);
    } else if (typeof action.payload.data.data === 'object') {
      Object.keys(action.payload.data.data).forEach((key) => {
        message += `\n${action.payload.data.data[key]}`;
      });
    }

    displayError(message);
  }

  return next(action);
};

const rootReducer = combineReducers({
  spin: spinSlice.reducer,
  windowSize: windowSizeSlice.reducer,
  device: deviceSlice.reducer,
  pageData: pageDataSlice.reducer,
  sidebar: sidebarSlice.reducer,
  searchText: searchTextSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const createStore = () => {
  if ((window as any).store) return (window as any).store;

  return (window as any).store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => (
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(thunk, apiSlice.middleware, rtkQueryErrorLogger as Middleware<
        (action: Action<'specialAction'>) => number,
        RootState
      >)
    ),
  });
};

export const useAppDispatch = () => {
  const store = createStore();
  return store.dispatch;
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
