import React from 'react';
import { Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';

function RadioButton({
  title,
  checked = false,
  disabled = false,
  className = '',
  onChange,
  display = 'inline-block',
  isRequired = false,
  name,
}) {
  return (
    <li
      className={`radio-item ${display === 'inline-block' ? 'd-inline-block' : 'd-block'}`}
      role="radio"
      aria-checked={checked}
      aria-disabled={disabled}
      aria-required={isRequired}
    >
      <Label className={`me-2 user-select-none ${className}`}>
        {isRequired && <span className="text-danger me-1">*</span>}
        <Input
          type="radio"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        {` ${title || ''}`}
      </Label>
    </li>
  );
}

RadioButton.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  display: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  checked: false,
  disabled: false,
  className: '',
  display: 'inline-block',
  isRequired: false,
};

export default RadioButton;
