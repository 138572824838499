import React, { useState, useEffect, useRef, Fragment } from 'react';
import useAutosave from '../../../helpers/useAutosave';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  useGetUsersQuery,
  useGetSiteBriefingQuery,
  useUpdateSiteBriefingMutation,
  useGetMasterInstancesQuery,
  useImportFromPipedriveMutation,
  useGenerateOrientationStatementMutation,
  useGenerateSellingPointsMutation,
  useGeneratePageSellingPointsMutation,
  useAddImageToBriefingMutation,
} from '../../../../../common/api/apiSlice';
import { useUser } from 'common/hooks';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Table,
  Alert,
} from 'reactstrap';
import confirm from '../../../components/common/confirm';
import Checkbox from '../../../components/common/checkBox/';
import CustomButton from '../../../components/common/button';
import CustomSelect from '../../../components/common/custom-select';
import { isEmpty, map, cloneDeep, flatten, filter, debounce } from 'lodash-es';
import { setPageTitle, getSelectedOption, displaySuccess, displayError } from '../../../../../Utils';
import UserFeaturePage from '../../../components/common/user-feature-page';
import HeaderComponent from '../../../components/common/header-component';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../constants/routes';
import withRouter from '../../../helpers/withRouter';
import BottomActionToolbar from '../../../components/common/toolbar';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import './SiteBriefingForm.scss';
import RadioButton from 'Dashboard/src/components/common/radioButton';

const getUserOptions = (users) => {
  if (!isEmpty(users)) {
    return map(
      filter(users, (u) => u.email.toString().match(/@webgenius\.co\.nz/)),
      (user) => ({
        label: `${user.full_name} (${user.user_name} / ${user.email})`,
        value: user.id,
      }),
    );
  }
  return [];
};

// Automatically set textarea height depending on the number of lines.
const autoHeightTextArea = (e) => {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
};

const AutoHeightTextArea = ({ name, value, onChange, style }) => (
  <Input
    type="textarea"
    name={name}
    value={value}
    onChange={(e) => {
      autoHeightTextArea(e);
      onChange(e);
    }}
    style={style}
  />
);

function AdviserBriefingForm(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: briefing } = useGetSiteBriefingQuery(id);
  const { data: users } = useGetUsersQuery();
  const user = useUser();
  const [updateBriefing] = useUpdateSiteBriefingMutation();
  const [importFromPipedrive] = useImportFromPipedriveMutation();
  const [generateOrientationStatement] = useGenerateOrientationStatementMutation();
  const [generateSellingPoints] = useGenerateSellingPointsMutation();
  const [generatePageSellingPoints] = useGeneratePageSellingPointsMutation();
  const [addImage] = useAddImageToBriefingMutation();

  const [tempBriefing, setTempBriefing] = useState(null);

  const { data: masterInstances } = useGetMasterInstancesQuery();
  const ref = useRef(null);
  const navRef = useRef(null);

  // Highlight the current section in the brief nav
  const scrollHandler = debounce(() => {
    const boxes = document.querySelectorAll('.anchor-box');
    document.querySelectorAll('.briefing-nav a').forEach((a) => a.classList.remove('active'));

    for (let i = 0; i < boxes.length; i++) {
      const box = boxes[i];
      const rect = box.getBoundingClientRect();
      const top = rect.top - 200;
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - 200;
      const percentVisible = 5;

      const isInViewport = !(
        Math.floor(100 - (((top >= 0 ? 0 : top) / +-rect.height) * 100)) < percentVisible
        || Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
      );

      if (isInViewport) {
        const boxName = [...box.classList].find((c) => c.match(/anchor-box-/)).replace(/anchor-box-/, '');
        const navLink = document.querySelector(`.briefing-nav a[href="#${boxName}"]`);
        navLink.classList.add('active');
        navLink.scrollIntoViewIfNeeded();

        break;
      }
    }
  }, 100);

  useEffect(() => {
    setPageTitle('Adviser Briefing');

    document.addEventListener('scroll', scrollHandler);

    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);

  useEffect(() => {
    if (briefing && !tempBriefing) {
      setTempBriefing({ ...briefing });

      setTimeout(() => {
        ref.current.querySelectorAll('textarea').forEach((t) => {
          autoHeightTextArea({ target: t });
        });
      }, 1000);
    }
  }, [briefing]);

  // Set the master_instance field to the first one in the list if it's empty.
  useEffect(() => {
    if (tempBriefing && masterInstances && isEmpty(tempBriefing.info.master_instance)) {
      setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, master_instance: masterInstances.at(0)?.id } });
    }
  }, [tempBriefing, masterInstances]);

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    {
      name: 'Briefings',
      url: replacePathParams(settingRoutes.siteBriefings, [{ key: 'pageNo', value: 1 }], props),
    },
    { name: 'Briefing', url: '' },
  ];

  useAutosave(() => {
    updateBriefing({ disableSpinner: true, ...tempBriefing });
  }, 30 * 1000);

  const onChange = (e) => {
    let value;

    if (e.target.type === 'checkbox' || e.target.type === 'radio') {
      value = e.target.checked;
    } else if (e.target.type === 'file') {
      value = e.target.files;
    } else {
      value = e.target.value;
    }

    setTempBriefing({
      ...tempBriefing,
      info: {
        ...tempBriefing.info,
        [e.target.name]: value,
      },
    });
  };

  const onSave = async (toSave = null) => {
    for (let k in tempBriefing.info) {
      if (tempBriefing.info[k] instanceof FileList) {
        [...tempBriefing.info[k]].forEach(async (file) => {
          const result = await addImage({ briefing: tempBriefing, name: k, file });
          if (result.error) {
            displayError(flatten(Object.values(result.error.data.data)));
          } else {
            displaySuccess(`File ${file.name} uploaded successfully.`);
          }
        });
      }
    }

    const result = await updateBriefing(toSave || tempBriefing);
    if (result.error) {
      displayError(flatten(Object.values(result.error.data.data)));
      return false;
    }

    displaySuccess('Briefing saved successfully!');
    return true;
  };

  return (
    <div ref={ref} className="SiteBriefing">
      <HeaderComponent setPath={{
        headingName: 'Briefing',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Settings',
        backToPath: replacePathParams(mainRoutes.setting, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="view_briefings">
        <Row>
          <Navbar expand className="briefing-nav h-auto mb-4" innerRef={navRef}>
            <Collapse isOpen={true} navbar>
              <Nav className="ms-2 align-items-center flex-wrap" navbar>
                <NavItem>
                  <NavLink href="#general">
                    General
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#phoneNumbers">
                    Phone Numbers
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#emailAddresses">
                    Email Addresses
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#businessPremises">
                    Business Premises
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#socialMedia">
                    Social Media Properties
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#businessProductServiceInfo">
                    Business &amp; Product/Service Info
                  </NavLink>
                </NavItem>
                {tempBriefing?.info?.asap_pages?.map((page, index) => (
                  <NavItem key={index}>
                    <NavLink href={`#asapPage${index}`}>
                      {page}
                    </NavLink>
                  </NavItem>
                ))}
                <NavItem>
                  <NavLink href="#clientConversationSummary">
                    Client Conversation Summary
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>

          <a className="anchor" id="general" />
          <Card className="mb-4 anchor-box anchor-box-general">
            <CardBody>
              <CardTitle>General</CardTitle>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Pipedrive Organisation URL</Label>
                    <aside className="hide-pdf">
                      This will overwrite existing data in this form.
                    </aside>
                    <Input
                      name="pipedrive_org_url"
                      className="mb-2"
                      value={tempBriefing?.info?.pipedrive_org_url}
                      onChange={onChange}
                    />
                    <Button
                      color="success"
                      onClick={async () => {
                        const result = await confirm({
                          title: (<strong>Confirm!</strong>),
                          message: 'This will overwrite existing data, are you sure?',
                          confirmText: 'Yes',
                          confirmColor: 'success',
                          cancelColor: 'btn btn-danger',
                          cancelText: 'No',
                        });
                        if (!result) {
                          return;
                        }

                        await onSave();
                        const result2 = await importFromPipedrive(tempBriefing);
                        if (result2.error) {
                          displayError(flatten(Object.values(result2.error.data.data)));
                          return;
                        }

                        setTempBriefing(result2.data.data);

                        setTimeout(() => {
                          ref.current.querySelectorAll('textarea').forEach((t) => {
                            autoHeightTextArea({ target: t });
                          });
                        }, 1000);

                        displaySuccess('Imported from Pipedrive successfully!');
                      }}
                      className="common-success-button button-lg fw-bold"
                    >
                      IMPORT FROM PIPEDRIVE
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Adviser</Label>
                    <CustomSelect
                      options={getUserOptions(users?.filter((u) => u.role?.name === 'Adviser'))}
                      value={getSelectedOption(getUserOptions(users), (() => {
                        if (tempBriefing?.info?.adviser) {
                          return tempBriefing?.info?.adviser;
                        }

                        if (user?.id && user.role?.name === 'Adviser') {
                          return user.id;
                        }
                      })())}
                      isClearable
                      isSearch={false}
                      className="flex-grow-1"
                      onChange={(e) => onChange({ target: { name: 'adviser', value: e.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Business name</Label>
                    <aside className="hide-pdf">
                      The brand name, rather than the full company or legal name. (ie not Web Genius Central New
                      Zealand Limited, but Web Genius).
                    </aside>
                    <Input name="company_name" value={tempBriefing?.info?.company_name} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Other products purchased</Label>
                    <Input name="other_products_purchased" value={tempBriefing?.info?.other_products_purchased} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Business owner name(s)</Label>
                    <aside>
                      Ensure 100% accurate spelling.
                    </aside>
                    {(tempBriefing?.info?.business_owners || [[]]).map((owner, index) => (
                      <div key={index}>
                        <Input
                          className="d-inline w-25 mb-2"
                          placeholder="First name"
                          name="business_owner_name_first"
                          value={owner[0]}
                          onChange={(e) => {
                            const owners = cloneDeep(tempBriefing.info.business_owners) || [];
                            owners[index] ||= [];
                            owners[index][0] = e.target.value;
                            setTempBriefing({
                              ...tempBriefing,
                              info: {
                                ...tempBriefing.info,
                                business_owners: owners,
                              },
                            });
                          }}
                        />
                        {' '}
                        <Input
                          className="d-inline w-25 mb-2"
                          placeholder="Surname"
                          name="business_owner_name_last"
                          value={owner[1]}
                          onChange={(e) => {
                            const owners = cloneDeep(tempBriefing.info.business_owners) || [];
                            owners[index] ||= [];
                            owners[index][1] = e.target.value;
                            setTempBriefing({
                              ...tempBriefing,
                              info: {
                                ...tempBriefing.info,
                                business_owners: owners,
                              },
                            });
                          }}
                        />
                      </div>
                    ))}
                    <Button
                      color="success"
                      onClick={() => {
                        const owners = cloneDeep(tempBriefing.info.business_owners || []);
                        owners.push([]);
                        setTempBriefing({
                          ...tempBriefing,
                          info: {
                            ...tempBriefing.info,
                            business_owners: owners,
                          },
                        });
                      }}
                      className="common-success-button button-lg fw-bold"
                    >
                      + ADD OWNER
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Existing Website</Label>
                    <aside>
                      Leave blank if not applicable.
                    </aside>
                    <Input name="existing_website" value={tempBriefing?.info?.existing_website} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="phoneNumbers" />
          <Card className="mb-4 anchor-box anchor-box-phoneNumbers">
            <CardBody>
              <CardTitle>Phone Numbers</CardTitle>
              <aside>
                Leave blank if not applicable.
              </aside>
              <Row>
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th>Number Type</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Feature in header of website?</th>
                        <th>Billing/admin enquiries?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Business landline
                        </td>
                        <td></td>
                        <td>
                          <Input name="phone_number_landline" value={tempBriefing?.info?.phone_number_landline} onChange={onChange} />
                        </td>
                        <td className="px-5">
                          <Checkbox
                            checked={tempBriefing?.info?.phone_number_in_header === 'phone_number_landline'}
                            onChange={(e) => {
                              onChange({ target: { name: 'phone_number_in_header', value: e.target.checked ? 'phone_number_landline' : '' } });
                            }}
                          />
                        </td>
                        <td className="px-5">
                          <Checkbox
                            checked={tempBriefing?.info?.phone_number_billing === 'phone_number_landline'}
                            onChange={(e) => {
                              onChange({ target: { name: 'phone_number_billing', value: e.target.checked ? 'phone_number_landline' : '' } });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Freephone number
                        </td>
                        <td></td>
                        <td>
                          <Input name="phone_number_freephone" value={tempBriefing?.info?.phone_number_freephone} onChange={onChange} />
                        </td>
                        <td className="px-5">
                          <Checkbox
                            checked={tempBriefing?.info?.phone_number_in_header === 'phone_number_freephone'}
                            onChange={(e) => {
                              onChange({ target: { name: 'phone_number_in_header', value: e.target.checked ? 'phone_number_freephone' : '' } });
                            }}
                          />
                        </td>
                        <td className="px-5">
                          <Checkbox
                            checked={tempBriefing?.info?.phone_number_billing === 'phone_number_freephone'}
                            onChange={(e) => {
                              onChange({ target: { name: 'phone_number_billing', value: e.target.checked ? 'phone_number_freephone' : '' } });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Owner/manager mobile 1
                        </td>
                        <td>
                          <Input name="name_mobile_1" value={tempBriefing?.info?.name_mobile_1} onChange={onChange} />
                        </td>
                        <td>
                          <Input name="phone_number_mobile_1" value={tempBriefing?.info?.phone_number_mobile_1} onChange={onChange} />
                        </td>
                        <td className="px-5">
                          <Checkbox
                            checked={tempBriefing?.info?.phone_number_in_header === 'phone_number_mobile_1'}
                            onChange={(e) => {
                              onChange({ target: { name: 'phone_number_in_header', value: e.target.checked ? 'phone_number_mobile_1' : '' } });
                            }}
                          />
                        </td>
                        <td className="px-5">
                          <Checkbox
                            checked={tempBriefing?.info?.phone_number_billing === 'phone_number_mobile_1'}
                            onChange={(e) => {
                              onChange({ target: { name: 'phone_number_billing', value: e.target.checked ? 'phone_number_mobile_1' : '' } });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Owner/manager mobile 2
                        </td>
                        <td>
                          <Input name="name_mobile_2" value={tempBriefing?.info?.name_mobile_2} onChange={onChange} />
                        </td>
                        <td>
                          <Input name="phone_number_mobile_2" value={tempBriefing?.info?.phone_number_mobile_2} onChange={onChange} />
                        </td>
                        <td className="px-5">
                          <Checkbox
                            checked={tempBriefing?.info?.phone_number_in_header === 'phone_number_mobile_2'}
                            onChange={(e) => {
                              onChange({ target: { name: 'phone_number_in_header', value: e.target.checked ? 'phone_number_mobile_2' : '' } });
                            }}
                          />
                        </td>
                        <td className="px-5">
                          <Checkbox
                            checked={tempBriefing?.info?.phone_number_billing === 'phone_number_mobile_2'}
                            onChange={(e) => {
                              onChange({ target: { name: 'phone_number_billing', value: e.target.checked ? 'phone_number_mobile_2' : '' } });
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Add any other phone numbers with details here</Label>
                    <AutoHeightTextArea
                      name="other_phone_numbers"
                      value={tempBriefing?.info?.other_phone_numbers}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="emailAddresses" />
          <Card className="mb-4 anchor-box anchor-box-emailAddresses">
            <CardBody>
              <CardTitle>Email Addresses</CardTitle>
              <aside>
                Leave blank if not applicable.
              </aside>
              <Row className="mb-3">
                <Col>
                  <h3>
                    General Business Email Addresses
                  </h3>
                  <Table>
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Web Marketing Reports</th>
                        <th>Online Enquiries</th>
                        <th>Billing/Admin Enquiries</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(tempBriefing?.info?.email_addr_general || [[]]).map((email, index) => (
                        <tr key={index}>
                          <td>
                            <Input
                              type="email"
                              value={email[0]}
                              onChange={(e) => {
                                const emails = cloneDeep(tempBriefing.info.email_addr_general) || [];
                                emails[index] ||= [];
                                emails[index][0] = e.target.value;
                                setTempBriefing({
                                  ...tempBriefing,
                                  info: { ...tempBriefing.info, email_addr_general: emails },
                                });
                              }}
                            />
                          </td>
                          {[1, 2, 3].map((i) => (
                            <td className="px-5" key={i}>
                              <Checkbox
                                checked={email[i]}
                                onChange={(e) => {
                                  const emails = cloneDeep(tempBriefing.info.email_addr_general) || [];
                                  emails[index] ||= [];

                                  if (i === 3) {
                                    // Special case for billing/admin enquiries, we only want one selected.
                                    emails.forEach((e, i) => {
                                      if (i !== index) {
                                        emails[i][3] = false;
                                      }
                                    });
                                  }

                                  emails[index][i] = e.target.checked;
                                  setTempBriefing({
                                    ...tempBriefing,
                                    info: { ...tempBriefing.info, email_addr_general: emails },
                                  });
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Button
                    color="success"
                    onClick={() => {
                      const emails = cloneDeep(tempBriefing.info.email_addr_general || []);
                      emails.push([]);
                      setTempBriefing({
                        ...tempBriefing,
                        info: { ...tempBriefing.info, email_addr_general: emails },
                      });
                    }}
                    className="common-success-button button-lg fw-bold"
                  >
                    + ADD EMAIL ADDRESS
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>
                    Business Owner/Manager Email Addresses
                  </h3>
                  <Table>
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Web Marketing Reports</th>
                        <th>Online Enquiries</th>
                        <th>Billing/Admin Enquiries</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(tempBriefing?.info?.email_addr_owner || [[]]).map((email, index) => (
                        <tr key={index}>
                          <td>
                            <Input
                              type="email"
                              value={email[0]}
                              onChange={(e) => {
                                const emails = cloneDeep(tempBriefing.info.email_addr_owner) || [];
                                emails[index] ||= [];
                                emails[index][0] = e.target.value;
                                setTempBriefing({
                                  ...tempBriefing,
                                  info: { ...tempBriefing.info, email_addr_owner: emails },
                                });
                              }}
                            />
                          </td>
                          {[1, 2, 3].map((i) => (
                            <td className="px-5" key={i}>
                              <Checkbox
                                checked={email[i]}
                                onChange={(e) => {
                                  const emails = cloneDeep(tempBriefing.info.email_addr_owner) || [];
                                  emails[index] ||= [];

                                  if (i === 3) {
                                    // Special case for billing/admin enquiries, we only want one selected.
                                    emails.forEach((e, i) => {
                                      if (i !== index) {
                                        emails[i][3] = false;
                                      }
                                    });
                                  }

                                  emails[index][i] = e.target.checked;
                                  setTempBriefing({
                                    ...tempBriefing,
                                    info: { ...tempBriefing.info, email_addr_owner: emails },
                                  });
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Button
                    color="success"
                    onClick={() => {
                      const emails = cloneDeep(tempBriefing.info.email_addr_owner || []);
                      emails.push([]);
                      setTempBriefing({
                        ...tempBriefing,
                        info: { ...tempBriefing.info, email_addr_owner: emails },
                      });
                    }}
                    className="common-success-button button-lg fw-bold"
                  >
                    + ADD EMAIL ADDRESS
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="businessPremises" />
          <Card className="mb-4 anchor-box anchor-box-businessPremises">
            <CardBody>
              <CardTitle>Business Premises</CardTitle>

              {(tempBriefing?.info?.premiseses || [{}]).map((premises, index) => (
                <Fragment key={index}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <h3>Premises Type</h3>
                        {[
                          'Home office',
                          'Office',
                          'Shop',
                          'Showroom',
                          'Workshop',
                          'Clinic',
                          'Gallery',
                          'Studio',
                          'Other',
                        ].map((type) => (
                          <Fragment key={type}>
                            <Checkbox
                              key={type}
                              title={type}
                              checked={(premises.types || []).includes(type)}
                              onChange={() => {
                                const premisesesClone = cloneDeep(tempBriefing?.info?.premiseses) || {};
                                premisesesClone[index].types ||= [];

                                if (premisesesClone[index].types.includes(type)) {
                                  premisesesClone[index].types.splice(premisesesClone[index].types.indexOf(type), 1);
                                } else {
                                  premisesesClone[index].types.push(type);
                                }

                                setTempBriefing({
                                  ...tempBriefing,
                                  info: { ...tempBriefing.info, premiseses: premisesesClone },
                                });
                              }}
                            />
                            {type === 'Home office' && premises?.types?.includes('Home office') && (
                              <div className="ms-4">
                                <Checkbox
                                  title="Are they happy for their address to be publicly displayed?"
                                  checked={premises.home_office_public}
                                  onChange={(e) => {
                                    const premisesesClone = cloneDeep(tempBriefing?.info?.premiseses);
                                    premisesesClone[index].home_office_public = e.target.checked;
                                    setTempBriefing({
                                      ...tempBriefing,
                                      info: { ...tempBriefing.info, premiseses: premisesesClone },
                                    });
                                  }}
                                />
                              </div>
                            )}
                            {type === 'Other' && premises?.types?.includes('Other') && (
                              <div className="ms-4">
                                <Input
                                  placeholder="Please specify"
                                  value={premises.other}
                                  onChange={(e) => {
                                    const premisesesClone = cloneDeep(tempBriefing?.info?.premiseses);
                                    premisesesClone[index].other = e.target.value;
                                    setTempBriefing({
                                      ...tempBriefing,
                                      info: { ...tempBriefing.info, premiseses: premisesesClone },
                                    });
                                  }}
                                />
                              </div>
                            )}
                            <br />
                          </Fragment>
                        ))}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          Business location
                        </Label>
                        <Input
                          value={premises.location}
                          onChange={(e) => {
                            const premisesesClone = cloneDeep(tempBriefing?.info?.premiseses);
                            premisesesClone[index].location = e.target.value;
                            setTempBriefing({
                              ...tempBriefing,
                              info: { ...tempBriefing.info, premiseses: premisesesClone },
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Fragment>
              ))}
              <Button
                color="success"
                onClick={() => {
                  const premiseses = cloneDeep(tempBriefing.info.premiseses || []);
                  premiseses.push({});
                  setTempBriefing({
                    ...tempBriefing,
                    info: { ...tempBriefing.info, premiseses },
                  });
                }}
                className="common-success-button button-lg fw-bold"
              >
                + ADD PREMISES
              </Button>

              <Row className="mt-3">
                <Col>
                  <Label>
                    Postal address
                  </Label>
                  <Input
                    value={tempBriefing?.info?.postal_address}
                    onChange={(e) => onChange({ target: { name: 'postal_address', value: e.target.value } })}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="socialMedia" />
          <Card className="mb-4 anchor-box anchor-box-socialMedia">
            <CardBody>
              <CardTitle>Social Media Properties</CardTitle>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Google Business Profile</Label>
                    <Input
                      value={tempBriefing?.info?.social_google_business_profile}
                      onChange={(e) => onChange({ target: { name: 'social_google_business_profile', value: e.target.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Facebook</Label>
                    <Input
                      value={tempBriefing?.info?.social_facebook}
                      onChange={(e) => onChange({ target: { name: 'social_facebook', value: e.target.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Instagram</Label>
                    <Input
                      value={tempBriefing?.info?.social_instagram}
                      onChange={(e) => onChange({ target: { name: 'social_instagram', value: e.target.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Youtube</Label>
                    <Input
                      value={tempBriefing?.info?.social_youtube}
                      onChange={(e) => onChange({ target: { name: 'social_youtube', value: e.target.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Other</Label>
                    <Input
                      value={tempBriefing?.info?.social_other}
                      onChange={(e) => onChange({ target: { name: 'social_other', value: e.target.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="businessProductServiceInfo" />
          <Card className="mb-4 anchor-box anchor-box-businessProductServiceInfo">
            <CardBody>
              <CardTitle>Business &amp; Product/Service Info</CardTitle>

              <Row className="mb-3">
                <Col>
                  <h3>
                    Draft Orientation Statement
                  </h3>
                  <p>
                    The Orientation Statement is a key element of the Web Genius formula, and is the plain English description of the business that sits in the header of the website on every page of the site.
                  </p>
                  <p>
                    It is called an Orientation Statement as its purpose is to “orient” the visitor and answer that crucial first question every website visitor subconsciously has in their head: “Am I in the right place?”
                  </p>
                  <p>
                    The Orientation Statement is not a trendy slogan, but a dry, down-to-earth statement designed to eliminate any doubt in the mind of the visitor about what is on offer.
                  </p>
                  <p>
                    The Orientation Statement consists of a “Business Services Description” of around 5 to 10 words, then a “Geographical Servicing Description” of around the same length (can be shorter or longer as necessary).
                  </p>
                  <p>
                    Here are some actual examples from Web Genius sites:
                  </p>
                  <p>
                    <ul>
                      <li>Residential Letting and Property Management | New Plymouth and the wider Taranaki region</li>
                      <li>Insurance Reinstatements, Residential and Commercial Construction | Based in Tauranga, servicing the central North Island</li>
                      <li>Blinds, curtains and shutters for homes and businesses | Servicing Greater Wellington, including Porirua, Hutt Valley and the Kapiti Coast</li>
                      <li>Constructing dairy sheds, infrastructure, homes and commercial buildings | Across the Waikato</li>
                      <li>Debt Collection & Credit Management | FeeSynergy for Accountants | Based in Christchurch; servicing New Zealand wide</li>
                    </ul>
                  </p>
                  <p>
                    Based on the above examples, write your best attempt at Business Services and Geographical Servicing
                    Descriptions, then click
                    {' '}
                    <strong>GENERATE DRAFT ORIENTATION STATEMENT</strong>
                  </p>
                  <FormGroup>
                    <Label>Business Services Description</Label>
                    <Input
                      value={tempBriefing?.info?.business_services_description}
                      onChange={(e) => onChange({ target: { name: 'business_services_description', value: e.target.value } })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Geographical Servicing Description</Label>
                    <Input
                      value={tempBriefing?.info?.geographical_servicing_description}
                      onChange={(e) => onChange({ target: { name: 'geographical_servicing_description', value: e.target.value } })}
                    />
                  </FormGroup>
                  <Button
                    color="success"
                    onClick={async () => {
                      await onSave();
                      const result = await generateOrientationStatement(tempBriefing);
                      setTempBriefing({
                        ...tempBriefing,
                        info: {
                          ...tempBriefing.info,
                          orientation_statement: result.data.data.info.orientation_statement,
                          selling_point_options: result.data.data.info.selling_point_options,
                        },
                      });
                    }}
                    className="common-success-button fw-bold"
                  >
                    GENERATE DRAFT ORIENTATION STATEMENT
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Here is the Draft Orientation Statement</Label>
                    <aside>Edit as you see fit</aside>
                    <Input
                      value={tempBriefing?.info?.orientation_statement}
                      onChange={(e) => onChange({ target: { name: 'orientation_statement', value: e.target.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {tempBriefing?.info?.orientation_statement && !isEmpty(tempBriefing?.info?.selling_point_options) && (
                <Row className="mb-3">
                  <Col>
                    <h3>
                      Business Strengths/Selling Points
                    </h3>
                    <p>
                      Please list the main strengths and selling points of [business name] and what it prides itself on being good at.
                    </p>
                    <p>
                      Based on the Orientation Statement provided, some possibilities might include the following.
                    </p>
                    <p>
                      Tick any that you believe apply:
                    </p>
                    <FormGroup>
                      {tempBriefing?.info?.selling_point_options?.map((point, index) => (
                        <Fragment key={index}>
                          <Checkbox
                            title={point}
                            checked={tempBriefing?.info?.selling_points?.includes(point)}
                            onChange={(e) => {
                              const sellingPoints = cloneDeep(tempBriefing.info.selling_points) || [];
                              if (e.target.checked) {
                                sellingPoints.push(point);
                              } else {
                                sellingPoints.splice(sellingPoints.indexOf(point), 1);
                              }

                              setTempBriefing({
                                ...tempBriefing,
                                info: { ...tempBriefing.info, selling_points: sellingPoints },
                              });
                            }}
                          />
                          <br />
                        </Fragment>
                      ))}
                    </FormGroup>
                    <Button
                      color="success"
                      onClick={async () => {
                        await onSave();
                        const result = await generateSellingPoints(tempBriefing);
                        setTempBriefing({
                          ...tempBriefing,
                          info: {
                            ...tempBriefing.info,
                            selling_point_options: result.data.data.info.selling_point_options,
                          },
                        });
                      }}
                      className="common-success-button fw-bold mb-2"
                    >
                      RE-GENERATE SELLING POINTS
                    </Button>
                    <p>
                      List any other selling points:
                    </p>
                    <AutoHeightTextArea
                      name="other_selling_points"
                      value={tempBriefing?.info?.other_selling_points}
                      onChange={onChange}
                    />
                  </Col>
                </Row>
              )}

              <Row className="mb-3">
                <Col>
                  <h3>
                    Product/Service (ASAP) pages
                  </h3>
                  <p>
                    The Dominator has three standard ASAP Pages (ie Action Sales and Authority Pages) which typically feature the business&apos;s three key products/services, but may also feature other important information related to the business:
                  </p>
                  {(tempBriefing?.info?.asap_pages || []).pad(3, '').map((page, index) => (
                    <Fragment key={index}>
                      <FormGroup className="d-flex">
                        <Label style={{ position: 'relative', top: '1rem' }}>
                          {index + 1}
                          .
                          &nbsp;&nbsp;
                        </Label>
                        <Input
                          style={{ maxWidth: '400px' }}
                          value={page}
                          onChange={(e) => {
                            const asapPages = cloneDeep(tempBriefing.info.asap_pages) || [];
                            asapPages[index] = e.target.value;
                            setTempBriefing({
                              ...tempBriefing,
                              info: { ...tempBriefing.info, asap_pages: asapPages },
                            });
                          }}
                        />
                        <CustomButton
                          size="xs"
                          title=" "
                          color="danger"
                          icon="fal fa-trash"
                          className="custom-simple-icon ms-2 mt-2"
                          style={{ maxHeight: '29px' }}
                          onClick={() => {
                            const asapPages = cloneDeep(tempBriefing.info.asap_pages || []);
                            asapPages.splice(index, 1);
                            setTempBriefing({
                              ...tempBriefing,
                              info: { ...tempBriefing.info, asap_pages: asapPages },
                            });
                          }}
                        />
                      </FormGroup>
                    </Fragment>
                  ))}
                  <Button
                    color="success"
                    onClick={() => {
                      const asapPages = cloneDeep(tempBriefing.info.asap_pages || []);
                      asapPages.push('');
                      setTempBriefing({
                        ...tempBriefing,
                        info: { ...tempBriefing.info, asap_pages: asapPages },
                      });
                    }}
                    className="common-success-button button-lg fw-bold mb-3"
                  >
                    + ADD PAGE
                  </Button>
                  <br />
                  <Button
                    color="success"
                    onClick={async () => {
                      await onSave();
                      const result = await generatePageSellingPoints(tempBriefing);
                      setTempBriefing({
                        ...tempBriefing,
                        info: {
                          ...tempBriefing.info,
                          asap_pages_selling_point_options: result.data.data.info.asap_pages_selling_point_options,
                        },
                      });
                    }}
                    className="common-success-button fw-bold"
                  >
                    GENERATE PAGE SELLING POINTS
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {tempBriefing?.info?.asap_pages_selling_point_options && tempBriefing?.info?.asap_pages?.map((page, pageIndex) => (
            <Fragment key={pageIndex}>
              <a className="anchor" id={`asapPage${pageIndex}`} />
              <Card className={`mb-4 anchor-box anchor-box-asapPage${pageIndex}`}>
                <CardBody>
                  <CardTitle>
                    ASAP Page
                    {' '}
                    {pageIndex + 1}
                    :
                    {' '}
                    {page}
                  </CardTitle>
                  <Row className="mb-3">
                    <Col>
                      <p>
                        Tick all the relevant strengths/selling points in relation to
                        {' '}
                        {page}
                        {' '}
                        from the options below, then add any others you can think of.
                      </p>
                      <FormGroup>
                        {tempBriefing?.info?.asap_pages_selling_point_options?.[pageIndex]?.map((point, optionIndex) => (
                          <Fragment key={optionIndex}>
                            <Checkbox
                              title={point}
                              checked={(tempBriefing?.info?.asap_pages_selling_points?.[pageIndex] || []).includes(point)}
                              onChange={(e) => {
                                const allPageSellingPoints = cloneDeep(tempBriefing.info.asap_pages_selling_points) || [];
                                const thisPageSellingPoints = cloneDeep(allPageSellingPoints?.[pageIndex]) || [];

                                if (e.target.checked) {
                                  thisPageSellingPoints.push(point);
                                } else {
                                  thisPageSellingPoints.splice(thisPageSellingPoints.indexOf(point), 1);
                                }

                                allPageSellingPoints[pageIndex] = thisPageSellingPoints;

                                setTempBriefing({
                                  ...tempBriefing,
                                  info: {
                                    ...tempBriefing.info,
                                    asap_pages_selling_points: allPageSellingPoints,
                                  },
                                });
                              }}
                            />
                            <br />
                          </Fragment>
                        ))}
                      </FormGroup>
                      <p>
                        List any other selling points:
                      </p>
                      <AutoHeightTextArea
                        name={`asap_pages_other_selling_points_${pageIndex}`}
                        value={tempBriefing?.info?.asap_pages_other_selling_points?.[pageIndex]}
                        onChange={(e) => {
                          const allPageOtherSellingPoints = cloneDeep(tempBriefing.info.asap_pages_other_selling_points) || [];
                          allPageOtherSellingPoints[pageIndex] = e.target.value;
                          setTempBriefing({
                            ...tempBriefing,
                            info: {
                              ...tempBriefing.info,
                              asap_pages_other_selling_points: allPageOtherSellingPoints,
                            },
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Fragment>
          ))}

          <a className="anchor" id="clientConversationSummary" />
          <Card className="mb-4 anchor-box anchor-box-clientConversationSummary">
            <CardBody>
              <CardTitle>
                Client Conversation Summary
              </CardTitle>
              <Alert color="danger">
                DO NOT INCLUDE ANYTHING HERE YOU WOULD NOT WANT THE CLIENT TO SEE!
              </Alert>

              <Row className="mb-3">
                <Col>
                  <p>
                    Completing this section will show you have listened and have passed their concerns on the next Web Genius person in the process.
                  </p>
                  <FormGroup>
                    <Label>Why have they come to Web Genius?</Label>
                    <AutoHeightTextArea
                      name="why_come_to_web_genius"
                      value={tempBriefing?.info?.why_come_to_web_genius}
                      onChange={onChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>What do they not like about their current website?</Label>
                    <AutoHeightTextArea
                      name="what_not_like_about_current_website"
                      value={tempBriefing?.info?.what_not_like_about_current_website}
                      onChange={onChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Is there anything they do like about their current website?</Label>
                    <AutoHeightTextArea
                      name="what_like_about_current_website"
                      value={tempBriefing?.info?.what_like_about_current_website}
                      onChange={onChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Why are they investing in a new website?</Label>
                    <AutoHeightTextArea
                      name="why_investing_in_new_website"
                      value={tempBriefing?.info?.why_investing_in_new_website}
                      onChange={onChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>What are their delivery expectations?</Label>
                    <div>
                      <RadioButton
                        title="Happy with the standard 10 - 12 weeks"
                        checked={tempBriefing?.info?.delivery_expectations === 'standard'}
                        onChange={() => onChange({ target: { name: 'delivery_expectations', value: 'standard' } })}
                      />
                    </div>
                    <div>
                      <RadioButton
                        title="Urgent timeframe - needed by:"
                        checked={tempBriefing?.info?.delivery_expectations === 'urgent'}
                        onChange={() => onChange({ target: { name: 'delivery_expectations', value: 'urgent' } })}
                      />
                    </div>
                    <div>
                      <RadioButton
                        title="Not needed until:"
                        checked={tempBriefing?.info?.delivery_expectations === 'not_urgent'}
                        onChange={() => onChange({ target: { name: 'delivery_expectations', value: 'not_urgent' } })}
                      />
                    </div>
                  </FormGroup>
                  {tempBriefing?.info?.delivery_expectations && tempBriefing?.info?.delivery_expectations !== 'standard' && (
                    <>
                      <FormGroup className="ms-4">
                        <Label>Delivery date expectation</Label>
                        <Input
                          type="date"
                          name="delivery_expectations_date"
                          value={tempBriefing?.info?.delivery_expectations_date}
                          onChange={onChange}
                        />
                      </FormGroup>
                      <FormGroup className="ms-4">
                        <Label>Delivery expectation comments</Label>
                        <AutoHeightTextArea
                          name="delivery_expectations_comments"
                          value={tempBriefing?.info?.delivery_expectations_comments}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </>
                  )}
                  <FormGroup>
                    <Label>Does the business have a logo/visual brand that will be used on the website?</Label>
                    <div>
                      <RadioButton
                        title="Yes"
                        checked={tempBriefing?.info?.logo_visual_brand}
                        onChange={() => onChange({ target: { type: 'radio', name: 'logo_visual_brand', checked: true } })}
                      />
                    </div>
                    <div>
                      <RadioButton
                        title="No"
                        checked={!tempBriefing?.info?.logo_visual_brand}
                        onChange={() => onChange({ target: { type: 'radio', name: 'logo_visual_brand', checked: false } })}
                      />
                    </div>
                  </FormGroup>
                  {tempBriefing?.info?.logo_visual_brand && (
                    <FormGroup className="ms-4">
                      <Label className="d-block">Upload images</Label>
                      {isEmpty(tempBriefing?.info?.logo)
                        ? (
                            <Input
                              type="file"
                              accept="image/png, image/jpeg"
                              name="logo"
                              onChange={onChange}
                              multiple
                            />
                          )
                        : (
                            <>
                              Images are set --
                              {' '}
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, logo: null } });
                                }}
                              >
                                click here
                              </a>
                              {' '}
                              to add new images.
                            </>
                          )}
                    </FormGroup>
                  )}
                  {!tempBriefing?.info?.logo_visual_brand && (
                    <FormGroup className="ms-4">
                      <Label>What is their plan regarding getting a logo/brand as this is usually required before the website&apos;s visuals can be designed.</Label>
                      <AutoHeightTextArea
                        name="logo_visual_brand_plan"
                        value={tempBriefing?.info?.logo_visual_brand_plan}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label>Other comments to pass on from conversations with clients to capture all relevant communication to date.</Label>
                    <AutoHeightTextArea
                      name="other_comments"
                      value={tempBriefing?.info?.other_comments}
                      onChange={onChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Paste in all relevant Pipedrive notes</Label>
                    <AutoHeightTextArea
                      name="pipedrive_notes"
                      value={tempBriefing?.info?.pipedrive_notes}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="mb-4 anchor-box anchor-box-clientConversationSummary">
            <CardBody>
              <CardTitle>
                Finalise Brief
              </CardTitle>
              <Row className="mb-3">
                <Button
                  color="success"
                  onClick={async () => {
                    const result = await confirm({
                      title: (<strong>Confirm!</strong>),
                      message: 'This will send the brief back to the Project Coordinator, are you sure?',
                      confirmText: 'Yes',
                      confirmColor: 'success',
                      cancelColor: 'btn btn-danger',
                      cancelText: 'No',
                    });
                    if (!result) {
                      return;
                    }

                    await onSave({ ...tempBriefing, adviser_completed: true });
                    navigate(replacePathParams(settingRoutes.siteBriefings, [], props));
                  }}
                  className="common-success-button button-lg fw-bold"
                >
                  FINALISE BRIEF
                </Button>
              </Row>
            </CardBody>
          </Card>
        </Row>

        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={() => onSave()}
              onContinue={replacePathParams(settingRoutes.siteBriefings, [], props)}
            />
          )}
        />
      </UserFeaturePage>
    </div>
  );
}

export default withRouter(AdviserBriefingForm);
